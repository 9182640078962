module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Achievable","short_name":"Achievable","start_url":"/","background_color":"#2C2E38","theme_color":"#15A5DF","display":"minimal-ui","icon":"src/images/favicon-194x194.png","icons":[{"src":"/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"183bcf8375f63076edd6fb80874d6f1f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
