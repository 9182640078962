/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import client from './apolloClient';
import './src/styles/global.scss';

async function handleRequest(event) {
  const response = await fetch(event.request);

  if (
    event.request.url.indexOf('https://api.fontshare.com/v2/css') === 0 &&
    response.status < 400
  ) {
    // Assuming you have a specific cache name setup
    const cache = await caches.open('fontshare-stylesheets');
    const cacheResponse = await cache.match(event.request);
    if (cacheResponse) {
      return cacheResponse;
    }
    const css = await response.text();
    const patched = css.replace(/}/g, 'font-display: swap; }');
    const newResponse = new Response(patched, { headers: response.headers });
    cache.put(event.request, newResponse.clone());
    return newResponse;
  }
  return response;
}

self.addEventListener('fetch', (event) => {
  // eslint-disable-line no-restricted-globals
  event.respondWith(handleRequest(event));
});

export const wrapRootElement = (
  { element } // eslint-disable-line import/prefer-default-export
) => <ApolloProvider client={client}>{element}</ApolloProvider>;

export const onClientEntry = () => {
  // 20221229JP: The `var AffiliateID` in src/html.js is necessary for this to work.
  const scriptPap = document.createElement('script');
  scriptPap.id = 'pap_x2s6df8d';
  scriptPap.onload = () => {
    try {
      PostAffTracker.setAccountId('default1');
      const isAffiliate = /a_aid/.test(window.location.hash);
      if (!isAffiliate) {
        AffiliateID = 'organic';
      }
      PostAffTracker.track();
    } catch (err) {
      console.log('PAP error');
      console.log(err);
    }
  };
  scriptPap.src = 'https://achievable.postaffiliatepro.com/scripts/6zkj249';
  document.head.appendChild(scriptPap);

  setTimeout(() => {
    const scriptZendesk = document.createElement('script');
    scriptZendesk.id = 'ze-snippet';
    scriptZendesk.src =
      'https://static.zdassets.com/ekr/snippet.js?key=fa7cfe34-02d8-41f1-853a-722f318f1fc4';
    document.head.appendChild(scriptZendesk);
  }, 10 * 1000);

  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '406600603249989');
  fbq('track', 'PageView');
};
