import fetch from 'isomorphic-fetch';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
// import { init } from '@sentry/browser'; // TODO

const GRAPHQL_URL = process.env.GRAPHQL_URL; // eslint-disable-line prefer-destructuring
// const RAVEN_DSN = process.env.RAVEN_DSN; // eslint-disable-line prefer-destructuring
// const STAGE = process.env.STAGE; // eslint-disable-line prefer-destructuring

// if (RAVEN_DSN) {
//   init({
//     dsn: RAVEN_DSN,
//     environment: STAGE,
//   });
// }

const clientCache = new InMemoryCache({
  dataIdFromObject: (o) => o.uuid || null,
});

const stateLink = withClientState({
  cache: clientCache,
  resolvers: {},
  defaults: {},
});

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'same-origin'
});

const client = new ApolloClient({
  fetch,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.info('[GraphQL error]', { message, locations, path }); // eslint-disable-line no-console
        });
      }
      if (networkError) {
        console.log('[Network error]', networkError); // eslint-disable-line no-console
      }
    }),
    stateLink,
    httpLink,
  ]),
  cache: clientCache,
});

export default client;
